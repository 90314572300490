
import { defineComponent, computed, ref, watch } from "vue";
import { format } from "date-fns";

export default defineComponent({
  props: {
    headers: Object,
    records: Array
  },

  emits: ["selected", "delete", "edit"],

  setup(props, { emit }) {
    const selected = ref([0]);

    function selectAll(e: any) {
      const iS = e.target.checked;

      if (!iS) {
        selected.value = [];
        return false;
      }

      props.records?.forEach((r: any) => {
        if (typeof r === "object" && r !== null) {
          const id = r["id"] || 0;
          selected.value.push(id);
        }
      });
    }

    function deleteItem(i: any) {
      emit("delete", i);
    }

    function editItem(record: any) {
      emit("edit", record);
    }

    const headerCount = computed(() => {
      const h = props.headers;

      if (h !== undefined) {
        return Object.keys(h).length + 1;
      }

      return 1;
    });

    const dark = computed(() => false);
    //window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)

    function formatLabel(item: any, key = "id") {
      if (typeof item === "object" && item !== null) {
        if (item.name) {
          return item.name;
        } else if (item.title) {
          return item.title;
        }
      } else {
        if (key == "created_at" || key == "updated_at") {
          return format(new Date(item), "PPPP");
        } else {
          return item;
        }
      }
    }

    watch(
      () => selected.value,
      v => {
        emit("selected", v);
      }
    );

    return {
      deleteItem,
      editItem,
      headerCount,
      formatLabel,
      selected,
      selectAll,
      dark
    };
  }
});
