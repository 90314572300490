
import store from "../services/store";
import { defineComponent, computed, onBeforeMount, ref } from "vue";
import { format } from "date-fns";

export default defineComponent({
  props: {
    routes: Array,
    user: Object,
    notify: Boolean
  },

  emits: ["loggedout"],

  setup(props, { emit }) {
    function logoutUser() {
      store.dispatch("logout").then(() => {
        emit("loggedout");
      });
    }
    const department = process.env.VUE_APP_NAME;
    const company = process.env.VUE_APP_COMPANY;

    const fullscreen = ref(false);

    const dark = computed(() => false);
    const d =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;

    function departmentUrl(name: "VUE_APP_PRICE_URL") {
      return process.env[name];
    }

    function toggleNav() {
      document.getElementById("sideNav")?.classList.toggle("d-none");
      fullscreen.value = !fullscreen.value;
    }

    function formatDate(d: any, f = "PPPP") {
      return format(d, f);
    }

    function printPage() {
      document.title = `${document.title} - ${formatDate(new Date(), "PPpp")}`;
      window.print();
    }

    onBeforeMount(() => {
      if (d) {
        // document.body.classList.add('dark-mode')
        // document.body.setAttribute('theme', 'dark')
      }
    });

    return {
      logoutUser,
      department,
      company,
      toggleNav,
      printPage,
      departmentUrl,
      dark,
      fullscreen
    };
  }
});
