
import { defineComponent, computed, onMounted, toRefs } from "vue";
import store from "../../../common/services/store";
import router from "./router";

export default defineComponent({
  setup() {
    const { user } = toRefs(store.state);
    const routes = computed(() => router.options.routes);
    const authenticated = computed(() => store.getters.authenticated);

    function promptLogin() {
      router.push("/auth");
    }

    return {
      routes,
      authenticated,
      user,
      promptLogin
    };
  }
});
