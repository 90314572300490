import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import Base from "../../../../common/components/Base.vue";
import { computed } from "vue";
import store from "../../../../common/services/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/auth",
    name: "Authentication",
    component: () => import("@/views/LoginRegister.vue"),
    meta: {
      title: "Authentication",
      sidebar: false,
      icon: "growth"
    }
  },
  {
    path: "/",
    name: "Home",
    component: Base,
    meta: {
      sidebar: true
    },
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: Home,
        meta: {
          title: "Home",
          sidebar: true,
          icon: "home-alt"
        }
      },
      {
        path: "/growth",
        name: "Growth Chart",
        component: Home,
        meta: {
          title: "Growth Progress Charts",
          sidebar: true,
          icon: "growth"
        }
      },
      {
        path: "/users",
        name: "User Accounts",
        component: () => import("@/views/Users.vue"),
        meta: {
          title: "User Accounts",
          sidebar: false,
          icon: "growth"
        }
      }
    ]
  },
  {
    path: "/sales",
    name: "Sales",
    component: Base,
    meta: {
      sidebar: true
    },
    children: [
      {
        path: "/orders",
        name: "Orders",
        component: Base,
        meta: {
          sidebar: true,
          icon: "cart"
        },
        children: [
          {
            path: "orders",
            name: "All Orders",
            component: () => import("@/views/sales/Orders.vue"),
            meta: {
              title: "Customer Orders",
              sidebar: false,
              icon: "users"
            }
          },
          {
            path: "new",
            name: "New Order",
            component: () => import("@/views/sales/Orders.vue"),
            meta: {
              title: "Create New Order",
              sidebar: false,
              icon: "users"
            }
          },
          {
            path: "",
            name: "Orders",
            component: () => import("@/views/orders/CustomerOrders.vue"),
            meta: {
              title: "Customer Orders",
              sidebar: true,
              permission: "menu_viewAllOrders"
            }
          },
          {
            path: "weekly/summary",
            name: "Summary",
            component: () => import("@/views/orders/WeeklyOrderSummary.vue"),
            meta: {
              title: "Weekly Order Summary",
              sidebar: true,
              permission: "menu_viewOrdersSummary"
            }
          },
          // {
          // 	path: "reports/week",
          // 	name: "Summary",
          // 	component: () => import("@/views/orders/Summary.vue"),
          // 	meta: {
          // 		title: "Weekly Order Summary",
          // 		sidebar: true,
          // 	},
          // },
          {
            path: "week",
            name: "Weekly Summary",
            component: () => import("@/views/orders/WeekOrders.vue"),
            meta: {
              title: "Weekly Order Summary",
              sidebar: false
            }
          },
          {
            path: "day/:day",
            name: "Day Orders",
            component: () => import("@/views/orders/Order.vue"),
            meta: {
              title: "Purchase Order Confirmation",
              sidebar: false
            },
            props: true
          },
          {
            path: "ten-weeks",
            name: "Sales Forecast",
            component: () => import("@/views/orders/TenWeekOrdersProjection.vue"),
            meta: {
              title: "10 Week Order Projection",
              sidebar: true,
              permission: "menu_viewSalesForecast"
            }
          },
          {
            path: "order/details/:id",
            name: "Order",
            component: () => import("@/views/orders/Order.vue"),
            meta: {
              title: "Purchase Order Confirmation",
              sidebar: false
            }
          },
          {
            path: "details/:id",
            name: "Order",
            component: () => import("@/views/sales/Invoice.vue"),
            meta: {
              title: "View Order",
              sidebar: false,
              icon: "users"
            }
          }
        ]
      },
      {
        path: "/invoices",
        name: "Invoices",
        component: Base,
        meta: {
          sidebar: true,
          icon: "files"
        },
        children: [
          {
            path: "",
            name: "All Invoices",
            component: () => import("@/views/sales/Products.vue"),
            meta: {
              title: "Manage Invoices",
              sidebar: true,
              icon: "users",
              permission: "menu_viewAllInvoices"
            }
          },
          {
            path: "new",
            name: "New Invoice",
            component: () => import("@/views/sales/Products.vue"),
            meta: {
              title: "Create New Invoice",
              sidebar: true,
              icon: "users",
              permission: "menu_viewNewInvoice"
            }
          },
          {
            path: "details/:id",
            name: "Invoice",
            component: () => import("@/views/sales/Invoice.vue"),
            meta: {
              title: "View Invoice",
              sidebar: false,
              icon: "users",
              permission: ""
            }
          }
        ]
      },
      {
        path: "/shipments",
        name: "Shipments",
        component: Base,
        meta: {
          sidebar: true,
          icon: "files"
        },
        children: [
          // {
          //   path: "tracker",
          //   name: "Shipment Tracker",
          //   component: () => import("@/views/sales/ShipmentTracker.vue"),
          //   meta: {
          //     title: "Shipment Tracker",
          //     sidebar: true,
          //     icon: "package",
          //     permission: "menu_viewAllInvoices"
          //   }
          // },
          {
            path: "agent",
            name: "Agent Shipments",
            component: () => import("@/views/sales/AgentShipments.vue"),
            meta: {
              title: "Agent Shipments",
              sidebar: true,
              icon: "package",
              permission: "menu_viewAllInvoices"
            }
          },
        ]
      },
    ]
  },
  {
    path: "/pricing",
    name: "Calculations",
    component: Base,
    meta: {
      sidebar: true
    },
    children: [
      {
        path: "/calculate",
        name: "Calculate",
        component: () => import("@/views/calculate/NewCalculation.vue"),
        meta: {
          title: "Calculate",
          sidebar: true,
          permission: "menu_viewCalculate",
          icon: "calc"
        }
      },

      {
        path: "/history",
        name: "History",
        component: Base,
        meta: {
          title: "Calculation History",
          sidebar: true,
          icon: "calendar"
        },
        children: [
          {
            path: "",
            name: "All History",
            component: () => import("@/views/calculate/Calculations.vue"),
            meta: {
              title: "Calculation History",
              sidebar: true,
              permission: "menu_viewAllHistory"
            }
          },

          {
            path: "customer",
            name: "By Customer",
            component: () => import("@/views/calculate/CustomerHistory.vue"),
            meta: {
              title: "Calculations Sort By Customer",
              sidebar: true,
              permission: "menu_viewHistoryByCustomer"
            }
          },

          {
            path: "product",
            name: "By Product",
            component: () => import("@/views/calculate/CalculationsByProductHistory.vue"),
            meta: {
              title: "Calculations Sort By Product",
              sidebar: true,
              permission: "menu_viewHistoryByProduct"
            }
          }
        ]
      },

      {
        path: "/calculations/:id",
        name: "View Calculation",
        component: () => import("@/views/calculate/SingleCalculation.vue"),
        meta: {
          title: "View Calculation",
          sidebar: false,
          icon: "reload"
        }
      },

      {
        path: "/production-cost",
        name: "Production Cost",
        component: () => import("@/views/calculate/ProductionCost.vue"),
        meta: {
          title: "Production Cost",
          sidebar: true,
          permission: "menu_viewCalculate",
          icon: "calc"
        }
      },
      {
        path: "/margins",
        name: "Margins",
        component: Base,
        meta: {
          title: "Margins",
          sidebar: true,
          icon: "line-chart"
        },
        children: [
          {
            path: "customer",
            name: "By Customer",
            component: () => import("@/views/calculate/MarginsByCustomer.vue"),
            meta: {
              title: "Margins By Customer",
              sidebar: true,
              permission: "menu_viewAllHistory"
            }
          },
          {
            path: "product",
            name: "By Product",
            component: () => import("@/views/calculate/MarginsByProduct.vue"),
            meta: {
              title: "Margins By Product",
              sidebar: true,
              permission: "menu_viewAllHistory"
            }
          },
            ]
      },
    ]
  },
  {
    path: "/farm",
    name: "Farm",
    component: Base,
    meta: {
      sidebar: true,
      roles: ["accountant"]
    },
    children: [
      {
        path: "/farms",
        name: "Farms",
        component: () => import("@/views/farm/Farms.vue"),
        meta: {
          title: "All Farms",
          sidebar: true,
          permission: "menu_viewFarms",
          icon: "map-pin"
        }
      },
      {
        path: "/availability",
        name: "Raw Materials",
        component: () => import("@/views/farm/RawMaterials.vue"),
        meta: {
          title: "Raw Material Details",
          sidebar: true,
          permission: "menu_viewRawMaterials",
          icon: "archive-fill"
        }
      },
      {
        path: "/pack-plan",
        name: "Pack Plan",
        component: () => import("@/views/farm/PackPlans.vue"),
        meta: {
          title: "Pack Plan",
          sidebar: true,
          icon: "line-chart"
        }
      },
      {
        path: "/weekly-outload",
        name: "Weekly Outload",
        component: () => import("@/views/farm/WeeklyOutloads.vue"),
        meta: {
          title: "Weekly Outload",
          sidebar: true,
          icon: "line-chart"
        }
      },
      {
        path: "/warehouses",
        name: "Warehouses",
        component: () => import("@/views/farm/Warehouses.vue"),
        meta: {
          title: "All Warehouses",
          sidebar: true,
          permission: "",
          icon: "home"
        }
      },
      {
        path: "/stock",
        name: "Stock",
        component: () => import("@/views/farm/Stock.vue"),
        meta: {
          title: "All Stock",
          sidebar: true,
          permission: "",
          icon: "box"
        }
      },
      {
        path: "/stock/day/:date",
        name: "Stock Summary",
        component: () => import("@/views/farm/StockSummary.vue"),
        meta: {
          title: "Stock Summary",
          sidebar: false
        }
      },

      {
        path: "/packouts",
        name: "Daily Packouts",
        component: () => import("@/views/farm/Packouts.vue"),
        meta: {
          title: "Daily Packouts Summary",
          sidebar: false,
          icon: "box"
        }
      },
      {
        path: "/crates",
        name: "Crates",
        component: () => import("@/views/farm/Crates.vue"),
        meta: {
          sidebar: true,
          icon: "home"
        },
      },
      {
        path: "/crates/day/:date",
        name: "Crates Summary",
        component: () => import("@/views/farm/CratesSummary.vue"),
        meta: {
          title: "Crates Summary",
          sidebar: false
        }
      },
      {
        path: "/intake",
        name: "Intake",
        component: () => import("@/views/farm/DayIntake.vue"),
        meta: {
          title: "Daily Intake",
          sidebar: true,
          icon: "line-chart"
        }
      },
      {
        path: "/intake/day/:date",
        name: "Day Intake Summary",
        component: () => import("@/views/farm/IntakeSummary.vue"),
        meta: {
          title: "Day Intake Summary",
          sidebar: false
        }
      },
      {
        path: "/picks",
        name: "Picks",
        component: Base,
        meta: {
          sidebar: true,
          icon: "clock"
        },
        children: [
          {
            path: "",
            name: "Daily Picks",
            component: () => import("@/views/farm/DayPicks.vue"),
            meta: {
              title: "Daily Picks",
              sidebar: true,
              permission: "menu_viewDailyPicks"
            }
          },
          {
            path: "weekly",
            name: "Weekly Projections",
            component: () => import("@/views/farm/WeekPicks.vue"),
            meta: {
              title: "Weekly Projections",
              sidebar: true,
              permission: "menu_viewWeeklyProjections"
            }
          },
          {
            path: "week-summary",
            name: "Week Summary",
            component: () => import("@/views/farm/WeekPicksAnalysis.vue"),
            meta: {
              title: "Weekly Pick Summary",
              sidebar: false
            }
          },
          {
            path: "ten-weeks",
            name: "10 Week Forecast",
            component: () => import("@/views/farm/TenWeekPicks.vue"),
            meta: {
              title: "10 Week Pick Projection",
              sidebar: true,
              permission: "menu_viewTenWeekForecast"
            }
          },
          {
            path: "day/:date",
            name: "Day Picks",
            component: () => import("@/views/farm/Pick.vue"),
            meta: {
              title: "Day Picks",
              sidebar: false
            }
          },
          {
            path: "day/:day/all",
            name: "Picks Summary",
            component: () => import("@/views/farm/PickDayReport.vue"),
            meta: {
              title: "Day Pick & Intake Report",
              sidebar: false
            },
            props: true
          },
          {
            path: "week/:date",
            name: "Week Picks",
            component: () => import("@/views/farm/WeekPickSummary.vue"),
            meta: {
              title: "Weekly Projection Analysis",
              sidebar: false
            },
            props: true
          }
        ]
      },
      {
        path: "/forecasts",
        name: "Forecasts",
        component: Base,
        meta: {
          sidebar: true,
          icon: "clock"
        },
        children: [
          {
            path: "",
            name: "Set Forecasts",
            component: () => import("@/views/farm/Forecasts.vue"),
            meta: {
              title: "Set Forecasts",
              sidebar: true,
              permission: "menu_viewSetForecasts"
            }
          },
          {
            path: "summary",
            name: "Forecasts Summary",
            component: () => import("@/views/farm/ForecastSummary"),
            meta: {
              title: "Forecasts Summary",
              sidebar: true,
              permission: ""
            }
          }
        ]
      }
    ]
  },
  {
    path: "/costs",
    name: "Costs",
    component: Base,
    meta: {
      sidebar: true
    },
    children: [
      {
        path: "/costs/types",
        name: "Categories",
        component: () => import("@/views/costs/CostTypes.vue"),
        meta: {
          title: "Cost Types",
          sidebar: true,
          permission: "menu_viewCategories",
          icon: "sign-pound"
        }
      },

      {
        path: "/costs/view/fixed-costs",
        name: "Fixed Costs",
        component: () => import("@/views/costs/Costs.vue"),
        meta: {
          title: "Fixed Costs",
          sidebar: true,
          permission: "menu_viewFixedCosts",
          icon: "label"
        }
      },

      {
        path: "/costs/view",
        name: "Packaging",
        component: Base,
        meta: {
          title: "Packaging Costs",
          sidebar: true,
          icon: "tag"
        },
        children: [
          {
            path: "primary-packaging",
            name: "Primary Packaging",
            component: () => import("@/views/costs/Costs.vue"),
            meta: {
              title: "Primary Packaging",
              sidebar: true,
              permission: "menu_viewPrimaryPackaging"
            }
          },

          {
            path: "box-types",
            name: "Box Types",
            component: () => import("@/views/costs/Costs.vue"),
            meta: {
              title: "Box Types",
              sidebar: true,
              permission: "menu_viewBoxTypes"
            }
          },

          {
            path: "labelling",
            name: "Labelling",
            component: () => import("@/views/costs/Costs.vue"),
            meta: {
              title: "Labelling",
              sidebar: true,
              permission: "menu_viewLabelling"
            }
          },

          {
            path: "pallets",
            name: "Pallets",
            component: () => import("@/views/costs/Costs.vue"),
            meta: {
              title: "Pallet Costs",
              sidebar: true,
              permission: "menu_viewPallets"
            }
          }
        ]
      }
    ]
  },
  {
    path: "/pallets",
    component: Base,
    meta: {
      sidebar: true,
      title: "Pallets"
    },
    children: [
      {
        path: "/pallets/types",
        name: "Shipping Formats",
        component: () => import("@/views/pallets/PalletTypes.vue"),
        meta: {
          title: "Shipping Formats",
          sidebar: true,
          permission: "menu_viewShippingFormats",
          icon: "package"
        }
      },
      {
        path: "/pallets/boxes",
        name: "Shipping Boxes",
        component: () => import("@/views/pallets/Pallets.vue"),
        meta: {
          title: "Boxes Per Pallet/AKE",
          sidebar: true,
          permission: "menu_viewShippingBoxes",
          icon: "box"
        }
      }
    ]
  },
  {
    path: "/users",
    name: "Users",
    component: Base,
    meta: {
      sidebar: true
    },
    children: [
      {
        path: "/system-users",
        name: "System Users",
        component: () => import("@/views/customers/Customers.vue"),
        meta: {
          title: "System Users",
          icon: "users",
          sidebar: true
        }
      },
      {
        path: "/roles",
        name: "Roles",
        component: () => import("@/views/users/Roles.vue"),
        meta: {
          title: "Roles",
          icon: "account-setting",
          sidebar: true
        }
      }
    ]
  },
  {
    path: "/data",
    name: "Data",
    component: Base,
    meta: {
      sidebar: true
    },
    children: [
      {
        path: "/products",
        name: "Products",
        component: Base,
        meta: {
          title: "All Products",
          sidebar: true,
          icon: "bag"
        },
        children: [
          {
            path: "",
            name: "Products",
            component: () => import("@/views/products/Products.vue"),
            meta: {
              title: "All Products",
              sidebar: true,
              permission: "menu_viewProducts",
              icon: "bag"
            }
          },

          {
            path: "varieties",
            name: "Crops",
            component: () => import("@/views/products/Varieties.vue"),
            meta: {
              title: "Crops",
              sidebar: true,
              permission: "menu_viewCrops",
              icon: "bag"
            }
          }
        ]
      },
      {
        path: "/customers",
        name: "Customers",
        component: Base,
        meta: {
          sidebar: true,
          icon: "users"
        },
        children: [
          // {
          // 	path: "",
          // 	name: "All Customers",
          // 	component: () => import("@/views/customers/Customers.vue"),
          // 	meta: {
          // 		title: "All Customers",
          // 		sidebar: true,
          // 		permission: 'menu_viewAllCustomers',
          // 	},
          // },
          {
            path: "assignments",
            name: "Assignments",
            component: () => import("@/views/customers/Assignments.vue"),
            meta: {
              title: "Customer Assignments",
              sidebar: true,
              permission: "menu_viewCustomerAssignments"
            }
          },
          {
            path: "notification-emails",
            name: "Notification Emails",
            component: () => import("@/views/customers/InternalEmails.vue"),
            meta: {
              title: "Notification Emails",
              sidebar: true,
              permission: ""
            }
          }
        ]
      },
      {
        path: "/customer/:id",
        name: "Single Customer",
        component: () => import("@/views/customers/Customer.vue"),
        meta: {
          title: "Single Customer",
          sidebar: false
        }
      },
      {
        path: "/agents",
        name: "Freight Agents",
        component: () => import("@/views/customers/Agents.vue"),
        meta: {
          title: "Freight Agents",
          sidebar: true,
          permission: "menu_viewFreightAgents",
          icon: "users-fill"
        }
      }
    ]
  },
  {
    path: "/other",
    name: "Reports",
    component: Base,
    meta: {
      sidebar: true
    },
    children: [
      {
        path: "/forgot-password",
        name: "Forgot Password",
        component: () => import("@/views/ForgotPassword.vue"),
        meta: {
          title: "Forgot Password",
          sidebar: false,
          icon: "growth"
        }
      },
      {
        path: "/change-password",
        name: "Change Password",
        component: () => import("@/views/ChangePassword.vue"),
        meta: {
          title: "Change Password",
          sidebar: false,
          icon: "growth"
        }
      },
      {
        path: "/reports/day/:today",
        name: "Orders Today",
        component: () => import("@/views/orders/DailySummary.vue"),
        meta: {
          title: "Day Order Summary",
          sidebar: false,
          icon: "calendar-alt"
        },
        props: true
      },
      {
        path: "/reports/week",
        name: "Orders This Week",
        component: () => import("@/views/orders/WeeklyReport.vue"),
        meta: {
          title: "Weekly Order Report",
          sidebar: false,
          icon: "calendar-alt"
        }
      },
      {
        path: "/reports/week/:day",
        name: "Orders For This Week",
        component: () => import("@/views/orders/WeeklyReport.vue"),
        meta: {
          title: "Weekly Order Report",
          sidebar: false,
          icon: "calendar-alt"
        },
        props: true
      },
      {
        path: "/orders/week",
        name: "Weekly Summary",
        component: () => import("@/views/orders/WeekOrders.vue"),
        meta: {
          title: "Weekly Order Summary",
          sidebar: false
        }
      },
      {
        path: "/reports/today",
        name: "Today",
        component: Base,
        meta: {
          title: "About",
          sidebar: true,
          icon: "line-chart"
        },
        children: [
          {
            path: "packout",
            name: "Packout Summary",
            component: () => import("@/views/About.vue"),
            meta: {
              title: "About",
              sidebar: true
            }
          },
          {
            path: "orders",
            name: "Orders Summary",
            component: () =>
              import("../../../../common/components/Profile.vue"),
            meta: {
              title: "Branding Settings",
              sidebar: true
            }
          }
        ]
      },
      {
        path: "/reports/week",
        name: "This Week",
        component: () => import("../../../../common/components/Profile.vue"),
        meta: {
          title: "My Profile",
          sidebar: true,
          icon: "calendar-alt"
        }
      },
      {
        path: "/reports/month",
        name: "This Month",
        component: () => import("../../../../common/components/Profile.vue"),
        meta: {
          title: "My Profile",
          sidebar: true,
          icon: "calendar"
        }
      }
    ]
  },
  {
    path: "/hidden",
    name: "Hidden",
    component: Base,
    meta: {
      sidebar: false
    },
    children: [
      {
        path: "/settings",
        name: "App Settings",
        component: () => import("@/views/Settings.vue"),
        meta: {
          title: "App Settings",
          sidebar: true,
          icon: "setting"
        }
      }
    ]
  },
  {
    name: "404",
    path: "/:pathMatch(.*)*",
    component: () => import("../../../../common/components/NotFound.vue"),
    meta: {
      title: "Page Not Found",
      sidebar: false
    }
  },
  {
    path: "/forbidden",
    name: "403",
    component: () => import("../../../../common/components/AccessDenied.vue"),
    meta: {
      title: "Access Denied",
      sidebar: false
    }
  },
  {
    path: "/contact",
    name: "Contact Support",
    component: () => import("../../../../common/components/Contact.vue"),
    meta: {
      title: "Contact Support",
      sidebar: false
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "current",
  linkExactActiveClass: "active",
  routes
});

const isAuthenticated = computed(() => store.getters.authenticated);
const userPermissions = computed(() => store.getters.userPermissions);

router.beforeEach(function(to, from, next) {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  next();
});

router.beforeEach((to, from, next) => {
  if (
    to.path !== "/auth" &&
    to.path !== "/forgot-password" &&
    to.path !== "/change-password" &&
    !isAuthenticated.value
  )
    next({ name: "Authentication" });
  else next();
});

router.beforeEach((to, from, next) => {
  const permission: any = to.meta.permission;

  if (permission) {
    const isAllowed = userPermissions.value.some((p: string) =>
      p.includes(permission)
    );
    if (!isAllowed) return next({ name: "403" });
  }
  next();
});

router.beforeEach((to, from, next) => {
  const titled = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);
  if (titled) {
    document.title = `${titled.meta.title} - ${process.env.VUE_APP_NAME} | ${process.env.VUE_APP_COMPANY}`;
  }

  next();
});

export default router;
