<template>
  <div class="ng-main ">
    <div class="ng-wrap ">
      <Topbar
        :routes="routes"
        :user="user"
        :notify="false"
        v-if="authenticated"
        @loggedout="promptLogin"
      />

      <div class="ng-content mt-5">
        <div class="container">
          <div class="ng-content-inner">
            <Sidebar :routes="routes" :user="user" v-if="authenticated" />

            <div class="ng-content-body">
              <router-view :key="$route.fullPath" />

              <!--              <Lowbar v-if="authenticated" />-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, toRefs } from "vue";
import store from "../../../common/services/store";
import router from "./router";

export default defineComponent({
  setup() {
    const { user } = toRefs(store.state);
    const routes = computed(() => router.options.routes);
    const authenticated = computed(() => store.getters.authenticated);

    function promptLogin() {
      router.push("/auth");
    }

    return {
      routes,
      authenticated,
      user,
      promptLogin
    };
  }
});
</script>
