<template>
  <div class="ng-footer d-none d-sm-block">
    <div class="container wide-xl">
      <div class="ng-footer-wrap g-2 px-4">
        <div class="ng-footer-copyright">&copy; {{ company }} {{ year }}</div>
        <div class="ng-footer-links">
          <ul class="nav nav-sm">
            <li class="nav-item d-print-none">
              <a class="nav-link" href="https://osen.co.ke" target="_blank">
                An Osen Concept
              </a>
            </li>
            <li class="nav-item d-none d-print-block">
              Printed on {{ formatDate(new Date(), "PPpp") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { format } from "date-fns";

export default defineComponent({
  setup() {
    const year = new Date().getFullYear();

    const company = process.env.VUE_APP_COMPANY;

    function formatDate(d: any, f = "PPPP") {
      return format(d, f);
    }

    return {
      year,
      company,
      formatDate
    };
  }
});
</script>
