<template>
  <div
    class="ng-aside d-none d-print-none"
    data-content="sideNav"
    data-toggle-overlay="true"
    data-toggle-screen="lg"
    data-toggle-body="true"
    id="sideNav"
  >
    <div class="ng-sidebar-menu" data-simplebar>
      <ul class="ng-menu">
        <template v-for="s in routes" :key="s.name">
          <li
            class="ng-menu-heading"
            v-if="s.meta.sidebar && checkParentIfChildHasAccess(s.children)"
          >
            <h6 class="overline-title" :title="s.name">
              {{ s.name || s.meta.title }}
            </h6>
          </li>

          <template v-if="s.children">
            <template v-for="r in s.children" :key="r.name">
              <li
                v-if="r.meta.sidebar"
                class="ng-menu-item"
                :class="{
                  'has-sub': r.children
                }"
              >
                <template v-if="r.children">
                  <a
                    href="#"
                    class="ng-menu-link ng-menu-toggle"
                    v-if="checkIfChildHasAccess(r.children)"
                  >
                    <span class="ng-menu-icon">
                      <em class="icon ni" :class="`ni-${r.meta.icon}`"></em>
                    </span>
                    <span class="ng-menu-text">
                      {{ r.name }}
                    </span>
                  </a>

                  <ul class="ng-menu-sub" v-if="r.children">
                    <template v-for="c in r.children" :key="c.path">
                      <li
                        class="ng-menu-item"
                        v-if="c.meta.sidebar && hasAccess(c.meta.permission)"
                      >
                        <router-link
                          :to="c.path.length ? r.path + `/` + c.path : r.path"
                          class="ng-menu-link"
                          :data-title="c.meta.title || c.name"
                        >
                          <span class="ng-menu-text">
                            {{ c.name }}
                          </span>
                        </router-link>
                      </li>
                    </template>
                  </ul>
                  <!-- .ng-menu-sub -->
                </template>

                <template v-else>
                  <router-link
                    :to="r.path"
                    class="ng-menu-link"
                    v-if="hasAccess(r.meta.permission)"
                    :data-title="r.meta.title || r.name"
                  >
                    <span class="ng-menu-icon">
                      <em class="icon ni" :class="`ni-${r.meta.icon}`"></em>
                    </span>
                    <span class="ng-menu-text">
                      {{ r.name }}
                    </span>
                  </router-link>
                </template>
              </li>
            </template>
          </template>
        </template>
      </ul>

      <ul class="ng-menu ng-menu-sm">
        <li class="ng-menu-heading d-none">
          <span>
            Help Center
          </span>
        </li>
        <li class="ng-menu-item">
          <router-link to="/contact" class="ng-menu-link">
            <span class="ng-menu-text">
              Contact Support
            </span>
          </router-link>
        </li>
        <li class="ng-menu-item" v-if="user?.role == 'admin'">
          <router-link to="/users" class="ng-menu-link">
            <span class="ng-menu-text">
              User Accounts
            </span>
          </router-link>
        </li>
        <li class="ng-menu-item" v-if="user?.role == 'admin'">
          <router-link to="/settings" class="ng-menu-link">
            <span class="ng-menu-text">
              App Settings
            </span>
          </router-link>
        </li>
      </ul>
    </div>

    <div class="ng-aside-close">
      <a href="#" class="toggle" data-target="sideNav">
        <em class="icon ni ni-cross"></em>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import store from "../services/store";

export default defineComponent({
  props: {
    routes: Array,
    user: Object
  },
  setup() {
    const userPermissions = computed(() => store.getters.userPermissions);

    function hasAccess(permission: any) {
      const access = true;
      if (permission) {
        const isAllowed = userPermissions.value.some((p: string) =>
          p.includes(permission)
        );
        if (!isAllowed) return false;
      }
      return access;
    }

    function checkIfChildHasAccess(routes: any) {
      const underPermissions: string[] = [];
      let access = false;
      routes.forEach(function(route: any) {
        if (route.meta.permission != undefined) {
          underPermissions.push(route.meta.permission);
        }
      });
      if (underPermissions.length) {
        access = userPermissions.value.some(
          (r: any) => underPermissions.indexOf(r) >= 0
        );
      }
      return access;
    }

    function checkParentIfChildHasAccess(parent: any) {
      const access: boolean[] = [];
      const underPermissions: string[] = [];
      parent.forEach(function(child: any) {
        if ("children" in child) {
          const result = checkIfChildHasAccess(child.children);
          access.push(result);
        } else {
          if (child.meta.permission != undefined) {
            underPermissions.push(child.meta.permission);
          }
          if (underPermissions.length) {
            const secondResult = userPermissions.value.some(
              (r: any) => underPermissions.indexOf(r) >= 0
            );
            access.push(secondResult);
          }
        }
      });
      return access.includes(true);
    }

    return {
      hasAccess,
      checkIfChildHasAccess,
      checkParentIfChildHasAccess
    };
  }
});
</script>
